import React from "react";
import Phone from "../images/mys_phone.jpg";
import { useTranslation } from "react-i18next";

const MainMessage = ({ header = null, text = null }) => {
  const { t } = useTranslation();
  const headerText = header || t("success.header");
  const subheaderText = text || t("success.subheader");
  return (
    <>
      <div className="image-container">
        <img alt={t("success.photoAltText")} src={Phone} className="main-image" />
      </div>
      <h1>{headerText}</h1>
      <p>{subheaderText}</p>
    </>
  );
};

export default MainMessage;
