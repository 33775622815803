import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LogoHeader from "./LogoHeader";

const PASSWORD_MIN_LENGTH = 11;

function PasswordForm({ onCreatePassword, setValidationErrors, validationErrors }) {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [isPasswordInvalid, setIsPasswordInvalid] = useState(false);
  const [isPasswordRepeatInvalid, setIsPasswordRepeatInvalid] = useState(false);

  const validateInputs = useCallback(() => {
    const errors = [];
    setIsPasswordInvalid(false);
    setIsPasswordRepeatInvalid(false);

    if (!password) {
      errors.push(t("passwordForm.validation.passwordEmpty"));
      setIsPasswordInvalid(true);
    }
    if (!!password && password.length < PASSWORD_MIN_LENGTH) {
      errors.push(t("passwordForm.validation.passwordTooShort"));
      setIsPasswordInvalid(true);
    }
    if (!passwordRepeat) {
      errors.push(t("passwordForm.validation.passwordRepeatEmpty"));
      setIsPasswordRepeatInvalid(true);
    }
    if (!!password && !!passwordRepeat && password !== passwordRepeat) {
      errors.push(t("passwordForm.validation.passwordMismatch"));
      setIsPasswordInvalid(true);
      setIsPasswordRepeatInvalid(true);
    }
    setValidationErrors(errors);
    return errors.length === 0;
  }, [password, passwordRepeat]);

  const onSubmit = useCallback(() => {
    const isValid = validateInputs();
    if (isValid) {
      onCreatePassword(password);
    }
  }, [password, validateInputs, onCreatePassword]);

  return (
    <>
      <LogoHeader />
      <h1>{t("passwordForm.header")}</h1>
      <p>{t("passwordForm.subheader")}</p>
      {validationErrors.length > 0 && (
        <ul className="validation-errors">
          {validationErrors.map((err, index) => (
            <li key={index}>{err}</li>
          ))}
        </ul>
      )}
      <form>
        <label htmlFor="password">{t("passwordForm.passwordLabel")}</label>
        <input
          className={`text ${isPasswordInvalid ? "error" : ""}`}
          name="password"
          type="password"
          value={password}
          onChange={e => setPassword(e.target.value)}
          autoComplete="off"
        />
        <label htmlFor="passwordRepeat">{t("passwordForm.repeatPasswordLabel")}</label>
        <input
          className={`text ${isPasswordRepeatInvalid ? "error" : ""}`}
          name="passwordRepeat"
          type="password"
          value={passwordRepeat}
          onChange={e => setPasswordRepeat(e.target.value)}
          autoComplete="off"
        />
        <input className="submit" type="button" value={t("passwordForm.submitButtonLabel")} onClick={onSubmit} />
      </form>
    </>
  );
}

export default PasswordForm;
