import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { httpRequest } from "../utilities/HttpUtilities";
import Loading from "../components/Loading";
import Error from "../components/Error";
import MainMessage from "../components/MainMessage";

const RequestStatus = {
  undefined: 0, //default, no action taken yet
  loading: 1,
  success: 2,
  error: 3
};

function UnsubscribeReminderEmailsFlow() {
  const { t } = useTranslation();

  const [status, setStatus] = useState(RequestStatus.undefined);

  const unsubscribeReminders = useCallback(async ({ uid, s, t }) => {
    setStatus(RequestStatus.loading);

    const [userId, signature, time] = [uid, s, t].map(encodeURIComponent);
    const uri = `/api/invite/unsubscribeReminders?uid=${userId}&s=${signature}&t=${time}`;
    const response = await httpRequest(uri, {
      method: "POST"
    });

    setStatus(response.ok ? RequestStatus.success : RequestStatus.error);
  }, []);

  useEffect(() => {
    if (!window.location.search) {
      setStatus(RequestStatus.error);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get("uid");
    const s = urlParams.get("s");
    const t = urlParams.get("t");

    if (uid == null || s == null || t == null) {
      //require all the parameters to be there before you send the request
      setStatus(RequestStatus.error);
    } else {
      unsubscribeReminders({ uid, s, t });
    }
  }, [unsubscribeReminders]);


  const isLoading = status === RequestStatus.loading || status === RequestStatus.undefined;
  const isSuccess = status === RequestStatus.success;
  return <>
    {isLoading ? <Loading /> : isSuccess ? <MainMessage header={t("unsubscribeReminderEmails.success.title")} /> : <Error />}
  </>;
}

export default UnsubscribeReminderEmailsFlow;
