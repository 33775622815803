import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { httpRequest } from "../utilities/HttpUtilities";
import Loading from "../components/Loading";
import MainMessage from "../components/MainMessage";

const RequestStatus = {
  undefined: 0, //default, no action taken yet
  loading: 1,
  success: 2,
  invalid: 3,
  error: 4
};

function DeviceVerificationFlow() {
  const { t } = useTranslation();

  const [status, setStatus] = useState(RequestStatus.undefined);

  const verifyDevice = useCallback(async ({ did, uid, exp, lc, h }) => {
    setStatus(RequestStatus.loading);
    const [deviceId, userId, eexp, elc, eh] = [did, uid, exp, lc, h].map(encodeURIComponent);
    const uri = `/api/device/verify?did=${deviceId}&uid=${userId}&lc=${elc}&exp=${eexp}&h=${eh}`;
    const response = await httpRequest(uri, {
      method: "GET"
    });

    if (response.ok) {
      setStatus(RequestStatus.success);
    } else {
      setStatus(RequestStatus.error);
    }
  }, []);

  useEffect(() => {
    if (!window.location.search) {
      setStatus(RequestStatus.error);
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);
    const did = urlParams.get("did");
    const uid = urlParams.get("uid");
    const lc = urlParams.get("lc");
    const exp = urlParams.get("exp");
    const h = urlParams.get("h");

    if (did == null || uid == null || lc == null || exp == null || h == null) {
      //require all the parameters to be there before you send the request
      setStatus(RequestStatus.error);
    } else {
      verifyDevice({ did, uid, exp, lc, h });
    }
  }, [verifyDevice]);

  const title = status === RequestStatus.success ? t("deviceVerification.success.title") : t("deviceVerification.error.title");
  const subtitle = status === RequestStatus.success ? t("deviceVerification.success.subtitle") : t("deviceVerification.error.subtitle");

  const isLoading = status === RequestStatus.loading || status === RequestStatus.undefined;
  return <>{isLoading ? <Loading /> : <MainMessage header={title} text={subtitle} />}</>;
}

export default DeviceVerificationFlow;
