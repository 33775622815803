import React from "react";
import { useTranslation } from "react-i18next";
import LogoHeader from "./LogoHeader";

function Error({ header = null, message }) {
  const { t } = useTranslation();
  const headerText = header || t("error.header");
  const messageText = message ? t(message) : t("error.subheader");

  return (
    <>
      <LogoHeader />
      <h2 className="error">{headerText}</h2>
      <p>{messageText}</p>
    </>
  );
}

export default Error;
