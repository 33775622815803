import React, { useEffect, useCallback, useState } from "react";
import { httpRequest } from "../utilities/HttpUtilities";
import { useErrorResponseHandler } from "../hooks/CommonHooks";
import Redirecting from "../components/Redirecting";
import Loading from "../components/Loading";
import Success from "../components/Success";
import Error from "../components/Error";
import PasswordForm from "../components/PasswordForm";

function PasswordFlow() {
  const [isParsed, setIsParsed] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isValidated, setIsValidated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);

  const [isError, error, handleErrorResponse] = useErrorResponseHandler(setIsValidated, setIsDone, setValidationErrors);

  const [email, setEmail] = useState();
  const [signature, setSignature] = useState();
  const [time, setTime] = useState();
  const [redirect, setRedirect] = useState();

  const encodeUri = useCallback(
    method => {
      const [e, s, t, r] = [email, signature, time, redirect].map(encodeURIComponent);
      return `/api/invite/${method}?e=${e}&s=${s}&t=${t}&r=${r}`;
    },
    [email, signature, time, redirect]
  );

  const validateRequest = useCallback(async () => {
    setIsLoading(true);
    const uri = encodeUri("validate");
    const result = await httpRequest(uri, {
      method: "GET"
    });
    setIsLoading(false);
    return result;
  }, [encodeUri]);

  const createPasswordRequest = useCallback(
    async password => {
      setIsLoading(true);
      const uri = encodeUri("createPassword");
      const result = await httpRequest(uri, {
        body: JSON.stringify({
          password
        }),
        headers: {
          "Content-type": "application/json"
        },
        method: "POST"
      });
      setIsLoading(false);
      return result;
    },
    [encodeUri]
  );

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      setEmail(urlParams.get("e"));
      setSignature(urlParams.get("s"));
      setTime(urlParams.get("t"));
      setRedirect(urlParams.get("r"));
      setIsParsed(true);
    } else {
      setIsEmpty(true);
    }
  }, []);

  useEffect(async () => {
    if (isParsed) {
      const response = await validateRequest();
      if (response.ok) {
        setIsValidated(true);
      } else {
        handleErrorResponse(response.error);
      }
    }
  }, [isParsed]);

  const onCreatePassword = useCallback(
    async password => {
      const response = await createPasswordRequest(password);
      if (response.ok) {
        setIsDone(true);
      } else {
        handleErrorResponse(response.error);
      }
    },
    [createPasswordRequest]
  );

  return (
    <>
      {isError && <Error message={error} />}
      {isLoading && <Loading />}
      {isEmpty && !isError && !isLoading && <Success />}
      {isValidated && !isError && !isLoading && !isDone && (
        <PasswordForm onCreatePassword={onCreatePassword} validationErrors={validationErrors} setValidationErrors={setValidationErrors} />
      )}
      {isDone && <>{!redirect ? <Success /> : <Redirecting redirectUrl={redirect} />}</>}
    </>
  );
}

export default PasswordFlow;
