import React from "react";
import { useTranslation } from "react-i18next";
import Loading from "./Loading";

function Redirecting({ redirectUrl }) {
  const { t } = useTranslation();

  if (!redirectUrl.startsWith("http")) redirectUrl = `http://${redirectUrl}`;
  window.location = redirectUrl;

  return (
    <>
      <Loading />
      <p className="text-center">
        {t("redirecting.infoText")}
        <a href={redirectUrl}>{redirectUrl}</a>
      </p>
    </>
  );
}

export default Redirecting;
