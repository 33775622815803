import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

export const useErrorResponseHandler = (setIsValidated, setIsDone, setValidationErrors) => {
  const { t } = useTranslation();

  const [isError, setIsError] = useState(false);
  const [error, setError] = useState();

  const handleErrorResponse = useCallback(error => {
    switch (error) {
      case "USER_WRONG_STATE":
        setIsValidated(true);
        setIsDone(true);
        break;
      case "USER_ACTIVATION_LINK_EXPIRED":
        setIsError(true);
        setError(t("responseErrors.USER_ACTIVATION_LINK_EXPIRED"));
        break;
      // insecure password error is treated as validation error,
      // allowing for re-filling the form, different from any other errors that render a separate error page
      case "PASSWORD_BREACHED":
        setValidationErrors([t("responseErrors.PASSWORD_BREACHED")]);
        break;
      default:
        setIsError(true);
        setError(t(`responseErrors.${error}`));
        break;
    }
  }, []);

  return [isError, error, handleErrorResponse];
};
