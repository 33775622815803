import React from "react";
import { Trans, useTranslation } from "react-i18next";
import StoreLinks from "./StoreLinks";
import MainMessage from "./MainMessage";

const Success = () => {
  const { t } = useTranslation();

  return (
    <>
      <MainMessage header={t("success.header")} text={t("success.appInfo")} />
      <StoreLinks />
      <p>
        <Trans i18nKey="success.webInfo" components={{ a: <a /> }} />
      </p>
    </>
  );
};

export default Success;
