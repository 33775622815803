import React from "react";
import AppStore from "../images/badge-apple-app-store_sv.png";
import PlayStore from "../images/badge-google-play_sv.png";

const StoreLinks = () => {
   return (
        <div className="store-links">
        <a href="https://play.google.com/store/apps/details?id=com.securitas.customerapp&amp;hl=en_US">
          <img alt="google play store" height="37" src={PlayStore} width="125" />
        </a>
        <a href="https://itunes.apple.com/se/app/mysecuritas/id1452779563?mt=8">
          <img alt="apple app store" height="37" src={AppStore} width="111" />
        </a>
      </div>
  );
};

export default StoreLinks;
