import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import LogoHeader from "./LogoHeader";

function EmailForm({ onCreateEmail, isSuccess }) {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [isEmailEmpty, setIsEmailEmpty] = useState(false);

  const validateEmail = useCallback(() => {
    setIsEmailEmpty(false);
    setIsEmailInvalid(false);

    if (!email) {
      setIsEmailEmpty(true);
      return false;
    }
    const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const isValid = regEmail.test(email);
    setIsEmailInvalid(!isValid);
    return isValid;
  }, [email]);

  const onSubmit = useCallback(() => {
    const isValid = validateEmail();
    if (isValid) {
      onCreateEmail(email);
    }
  }, [email, validateEmail, onCreateEmail]);

  const onResend = useCallback(() => {
    onCreateEmail(email);
  }, [email, onCreateEmail]);

  const onEnter = useCallback(
    e => {
      if (e.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  return (
    <>
      <LogoHeader />
      <h1>{t("emailForm.header")}</h1>
      {isSuccess ? (
        <>
          <p>
            <span>{t("emailForm.successMessagePre")}</span>
            <strong>{email}</strong>
            <span>{t("emailForm.successMessagePost")}</span>
          </p>
          <input className="secondary" type="button" value={t("emailForm.resendButtonLabel")} onClick={onResend} />
        </>
      ) : (
        <>
          <p>{t("emailForm.subheader")}</p>
          <form>
            <label htmlFor="email">{t("emailForm.emailLabel")}</label>
            <input
              className={`text ${isEmailEmpty || isEmailInvalid ? "error" : ""}`}
              name="email"
              type="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              onKeyDown={onEnter}
              autoComplete="off"
            />
            {isEmailEmpty && <div className="validation-errors">{t("emailForm.requiredEmail")}</div>}
            {isEmailInvalid && <div className="validation-errors">{t("emailForm.invalidEmail")}</div>}
            <input className="submit" type="button" value={t("emailForm.submitButtonLabel")} onClick={onSubmit} />
          </form>
        </>
      )}
    </>
  );
}

export default EmailForm;
