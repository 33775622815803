import React from "react";
import LogoHeader from "./LogoHeader";

function Loading() {
  return (
    <>
      <LogoHeader />
      <div className="loading">
        <div />
        <div />
        <div />
      </div>
    </>
  );
}

export default Loading;
