import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import detector from "i18next-browser-languagedetector";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from 'i18next-localstorage-backend';

const LANGUAGE_CODES = {
  ENGLISH: "en",
  DUTCH_NETHERLANDS: "nl-NL",
  DUTCH: "nl"
}

const defaultLanguage = LANGUAGE_CODES.ENGLISH;

const i18NextOptions = {
  appNameForLocalization: "mysOnboarding",
  localizationStorage: window.localStorage,
  cachedLocalizationPrefix: "i18next_res_",
  timestampInCacheProp: "i18nStamp"
}

const i18nextInit = () => {
  return i18next
    .use(detector)
    .use(initReactI18next)
    .use(ChainedBackend)
    .init(
      {
        debug: process.env.NODE_ENV === 'development',
        fallbackLng: (code) => {
          if (code === LANGUAGE_CODES.DUTCH) {
            return LANGUAGE_CODES.DUTCH_NETHERLANDS;
          }
          return defaultLanguage;
        },
        interpolation: {
          escapeValue: false
        },
        //Note: we're using ''(empty string) as default namespace, while starting to use namespaces the useAppTranslationSync appHook needs to be modified
        defaultNS: '',
        ns: [''],
        backend: {
          backends: [
            LocalStorageBackend,  // primary backend, local storage serving as a cache,
            HttpBackend,
          ],
          backendOptions: [{
            prefix: i18NextOptions.cachedLocalizationPrefix,
            expirationTime: 31 * 24 * 60 * 60 * 1000, //31 days of cache,
            store: i18NextOptions.localizationStorage
          },
          {
            loadPath: process.env.REACT_APP_ACCESS_API_ENDPOINT + '/localization/translations/' + i18NextOptions.appNameForLocalization + '/{{lng}}/{{ns}}?format=json',
          }]
        },
        detection: {
          order: ['querystring', 'navigator', 'htmlTag'], //note: default is ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag'],
        }
      },
      (err, t) => {
        if (err) {
          console.warn("Translation error:", err);
        }
      }
    );
};

export { i18nextInit, i18next, i18NextOptions };
