const httpRequest = async (endpoint, options) => {
  const headers = options.headers || {};
  const optionsWithHeaders = {
    ...options,
    headers: {
      ...headers,
      Accept: "application/json" // Accept header is required for React proxy to work
    }
  };
  try {
    const response = await fetch(endpoint, optionsWithHeaders);
    const body = await response.json();

    if (!response.ok || !body.ok) {
      if (body && body.error) {
        return {
          error: body.error
        };
      }
      return {
        error: "UNKNOWN_ERROR"
      };
    }
    return body;
  } catch (e) {
    return {
      error: e.message
    };
  }
};

export { httpRequest };
