import React, { useEffect, useMemo, useCallback, useState } from "react";
import { httpRequest } from "../utilities/HttpUtilities";
import { useErrorResponseHandler } from "../hooks/CommonHooks";
import { calculateHash } from "../utilities/StringUtilities";
import Loading from "../components/Loading";
import Error from "../components/Error";
import EmailForm from "../components/EmailForm";

function EmailFlow() {
  const [isParsed, setIsParsed] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);

  const [isError, error, handleErrorResponse] = useErrorResponseHandler(null, null, null);

  const [userId, setUserId] = useState();
  const [time, setTime] = useState();
  const [hash, setHash] = useState();

  useEffect(() => {
    if (window.location.search) {
      const urlParams = new URLSearchParams(window.location.search);
      setUserId(urlParams.get("userId"));
      setTime(urlParams.get("time"));
      setHash(urlParams.get("hash"));
      setIsParsed(true);
    } else {
      setIsEmpty(true);
    }
  }, []);

  const isHashCorrect = useMemo(() => {
    if (isParsed) {
      const str = `${userId},${time}`;
      const hashCode = calculateHash(str);
      return hashCode == hash;
    }
    return false;
  }, [isParsed, userId, time, hash]);

  const verifyEmailRequest = useCallback(
    async email => {
      setIsLoading(true);
      const uri = `/api/invite/verifyEmail?userId=${userId}&email=${email}`;
      const result = await httpRequest(uri, {
        method: "POST"
      });
      setIsLoading(false);
      return result;
    },
    [userId]
  );

  const onCreateEmail = useCallback(
    async email => {
      const response = await verifyEmailRequest(email);
      if (response.ok) {
        setIsDone(true);
      } else {
        handleErrorResponse(response.error);
      }
    },
    [verifyEmailRequest]
  );

  return (
    <>
      {isError && <Error message={error} />}
      {isEmpty && <Error />}
      {!isEmpty && !isHashCorrect && <Error />}
      {isLoading && <Loading />}
      {isHashCorrect && !isEmpty && !isError && !isLoading && <EmailForm onCreateEmail={onCreateEmail} isSuccess={isDone} />}
    </>
  );
}

export default EmailFlow;
