import React, { Suspense, useEffect } from "react";
import { i18nextInit } from "./i18next";
import PasswordFlow from "./screens/PasswordFlow";
import EmailFlow from "./screens/EmailFlow";
import DeviceVerificationFlow from "./screens/DeviceVerificationFlow";
import UnsubscribeReminderEmailsFlow from "./screens/UnsubscribeReminderEmailsFlow";
import Loading from "./components/Loading";
import { syncI18nCultures } from "./i18nextSync";
import "./App.css";

i18nextInit();

const RoutePaths = {
  unsubscribeReminderEmails: "/unsubscribe-reminders",
  deviceVerificationFlow: "/device-verification",
  emailFlow: "/sms-invitation",
  passwordFlow: "/",
}

function AppRoutes() {
  /* todo: when deciding whether or not it's time to move away from the 'routing' approach below 
  (eg. when there's a need to create a link between the below flows) 
   check out history package https://github.com/ReactTraining/history that facilitates history management 
   or react-router that uses history underneath but is a full blown library for routing 
   */
  const path = window.location.pathname;
  switch (path?.toLowerCase()) {
    case RoutePaths.unsubscribeReminderEmails:
      return <UnsubscribeReminderEmailsFlow />
    case RoutePaths.deviceVerificationFlow:
      return <DeviceVerificationFlow />
    case RoutePaths.emailFlow:
      return <EmailFlow />
    default:
      return <PasswordFlow />
  }
}

function App() {

  useEffect(() => {
    syncI18nCultures();
  }, [])

  return (
    //Note: Suspense with a fallback is for i18n for when the translations are not yet loaded, it should wrap the components that use translations
    <Suspense fallback={<Loading />}>
      <AppRoutes />
    </Suspense>
  );
}

export default App;
