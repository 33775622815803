import React from "react";
import Logo from "../images/mysecuritas-logo.png";

const LogoHeader = () => (
  <header>
    <img alt="MySecuritas" src={Logo} height="32" width="152" />
  </header>
);

export default LogoHeader;
